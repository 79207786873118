<div id="panel-control" class="page-layout simple fullwidth">
	<div fxLayout="row wrap" class="p-24">
		<div @onRenderUp class="fuse-card card-border-top card-fullwidth">
			<mat-tab-group [(selectedIndex)]="selectedIndex" (selectedTabChange)="onChangeTab($event)">
				<mat-tab fxFlex="33" label="{{ 'evaluation.tab.pending' | translate }}"> </mat-tab>
				<mat-tab fxFlex="33" label="{{ 'evaluation.tab.completed' | translate }}"> </mat-tab>
				<mat-tab fxFlex="33" label="{{ 'evaluation.tab.total' | translate }}"> </mat-tab>
			</mat-tab-group>

			<!-- Filters -->
			<div *ngIf="isBoss" class="p-36 filter-container" fxLayout="row">
				<div fxLayout="column" fxFlex="5 1 0">
					<div fxLayout="row wrap" class="statics-filters">
						<mat-form-field subscriptSizing="dynamic" dense appearance="outline" class="mr-8">
							<mat-label>{{ "evaluation.controlpanel.period" | translate }}</mat-label>
							<mat-select
								*ngIf="periodList && periodList.length"
								class="form-control"
								#filterPeriod
								[(value)]="selectedPeriod"
								(selectionChange)="onChangePeriod(filterPeriod.value)"
							>
								<mat-option *ngFor="let period of periodList" [value]="period.id">{{ period.name }}</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field subscriptSizing="dynamic" dense appearance="outline" class="mr-8">
							<mat-label>{{ "evaluation.controlpanel.departament" | translate }}</mat-label>
							<input
								class="form-control pointer"
								type="text"
								placeholder="{{ 'evaluation.controlpanel.departament' | translate }}"
								matInput
								[formControl]="formControl"
								[matAutocomplete]="auto"
							/>
							<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectDepartament()">
								<mat-option class="auto-option" *ngFor="let dep of filteredDepartaments | async" [value]="dep.departamento">
									{{ dep.departamento }}
								</mat-option>
							</mat-autocomplete>
							<button class="dep-clear-btn" *ngIf="selectedDepartament" matSuffix mat-icon-button aria-label="Clear" (click)="clearDepartament()">
								<mat-icon>close</mat-icon>
							</button>
						</mat-form-field>
						<mat-form-field subscriptSizing="dynamic" dense appearance="outline" class="mr-8">
							<mat-label>{{ "evaluation.controlpanel.puesto" | translate }}</mat-label>
							<mat-select class="form-control" #filterPuesto [(value)]="selectedPuesto" (selectionChange)="onChangePuesto(filterPuesto.value)">
								<mat-option value="">{{ "evaluation.filter.job.any" | translate }}</mat-option>
								<mat-option *ngFor="let job of jobsList" [value]="job.id">{{ job.puesto }}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<!-- Aspects -->
					<div *ngIf="currentPeriod && currentPeriod.isEnabledEvaluationAspect" fxLayout="row wrap" style="margin-top: 20px">
						<div *ngFor="let aspect of aspectsList">
							<mat-form-field subscriptSizing="dynamic" *ngIf="aspect.type === 'SELECT'" dense appearance="outline" class="mr-8">
								<mat-label>{{ aspect.name }}</mat-label>
								<mat-select class="form-control" [(value)]="selectedAspect[aspect.id]">
									<mat-option value="" (click)="onChangeAspect(aspect.id, '')">Cualquier {{ aspect.name }}</mat-option>
									<mat-option *ngFor="let value of aspect.values" [value]="value.id" (click)="onChangeAspect(aspect.id, value.id)">{{
										value.name
									}}</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
				</div>
				<div fxLayout="column" fxFlex="1 1 0" fxLayoutAlign="flex-end">
					<div (click)="applyFilters()">
						<button class="p-8 action-button pointer">{{ "evaluation.filter.btn" | translate }}</button>
					</div>
				</div>
			</div>

			<div fxLayout="row wrap" *ngIf="isBoss" fxLayoutAlign="space-between" class="notification-container">
				<span *ngIf="evaluations">{{ evaluations.length }} evaluaciones</span>
				<div (click)="sendAllPendingsEvaluationsMail()">
					<button class="p-8 action-button pointer" *ngIf="project?.id != 453">{{ "evaluation.modal.notifyAllUsers" | translate }}</button>
				</div>
			</div>

			<!-- Evaluations -->
			<div *ngIf="evaluationsLoaded" @slideUpList fxLayout="row wrap" fxLayoutAlign="center center" class="p-36 cards-container">
				<ng-container *ngFor="let eval of evaluations">
					<div
						fxLayout="column"
						[ngClass]="eval.personId == user.id ? 'fuse-card card-border-top card-auto' : 'fuse-card card-border-top card-colaborador'"
					>
						<!-- <mat-icon *ngIf="eval.personId == user.id" class="icon-badge secundary-text">bookmark</mat-icon> -->
						<div class="autoev-box" *ngIf="eval.personId == user.id" fxLayout="row" fxLayoutAlign="center flex-start">Autoevaluación</div>

						<colaborator-card
							[isDoneTab]="selectedIndex == 1 ? true : false"
							[evaluation]="eval"
							[period]="currentPeriod"
							[aspectsList]="aspectsList"
							[userId]="user.id"
							[projectName]="project.name"
						></colaborator-card>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>
